import React from "react"
import * as styles from "components/pc/lawPage/index.module.scss"


const PcContents: React.FC = React.memo(() => {
    return (
        <>
            <section className={styles.lawPage}>
                <h1 className={`${styles.lawPageTitle} fwb`}>特定商取引法に基づく表記</h1>
                <div className={styles.lawPageContainer}>
                    <div className={styles.lawPageContainerList}>
                        <dl className={styles.lawPageContainerItem}>
                            <dt className={`${styles.itemTitle} fwb`}>サービス名</dt>
                            <dd className={`${styles.itemText} fwn`}>Baluko Laundry Placeアプリ</dd>
                        </dl>
                        <dl className={styles.lawPageContainerItem}>
                            <dt className={`${styles.itemTitle} fwb`}>運営事業者名</dt>
                            <dd className={`${styles.itemText} fwn`}>株式会社OKULAB</dd>
                        </dl>
                        <dl className={styles.lawPageContainerItem}>
                            <dt className={`${styles.itemTitle} fwb`}>代表者名</dt>
                            <dd className={`${styles.itemText} fwn`}>久保田淳</dd>
                        </dl>
                        <dl className={styles.lawPageContainerItem}>
                            <dt className={`${styles.itemTitle} fwb`}>所在地</dt>
                            <dd className={`${styles.itemText} fwn`}>〒151-0062 東京都渋谷区元代々木町4-5</dd>
                        </dl>
                        <dl className={styles.lawPageContainerItem}>
                            <dt className={`${styles.itemTitle} fwb`}>お問い合わせ</dt>
                            <dd className={`${styles.itemText} fwn`}>info@okulab.co.jp</dd>
                        </dl>
                   </div>

                   <div className={styles.lawPageContainerList}>
                        <h4 className={styles.containerTitle}>ご利用料</h4>
                        <div>
                            <p className="fwn">
                                店舗にてQRコードを読み込み、コース選択画面および、決済画面にてご確認いただけます。消費税を含む価格を表示しています。 
                                なお、ランドリ－機器のご利用料以外のアプリのご利用、インストールは無料です。 <br></br>
                                （アプリダウンロードおよびご利用時のインターネット接続料・通信費用はお客さまにてご負担いただく必要がございます。）
                            </p>
                        </div>
                   </div>

                   <div className={styles.lawPageContainerList}>
                        <h4 className={styles.containerTitle}>代金の支払い時期・方法</h4>
                        <div>
                            <p className="fwn">
                                各種クレジットカードをご利用いただけます。 
                                店舗にてQRコードを読み込み、コースを選択した後、決済が完了すると、機器が稼働を開始します。稼働開始まで通信の状況により1～2分かかることがございます。<br></br> 
                                （各種クレジットカードの引き落とし時期はお客さまと各種決済サービスの契約により異なります。）
                            </p>
                        </div>
                   </div>

                   <div className={styles.lawPageContainerList}>
                        <h4 className={styles.containerTitle}>返金・キャンセルについて</h4>
                        <div className="fwn">
                            <p>
                                決済完了後に、洗濯物の入れ忘れなど、お客さまのご都合でキャンセルをすることはできません。 
                                下記いずれかの場合はご利用いただいた決済方法により、決済の取消しまたは返金の対応をいたします。 
                            </p>
                            <ul>
                                <li className={styles.lawPageTextList}>通知の状況により、選択したコースにおいて機器が正常に稼働しなかった場合</li>
                                <li className={styles.lawPageTextList}>機器に起因する事由により、選択したコースが正常に完了しなかった場合 </li>
                            </ul>
                            <p>※ただし、洗濯物の入れすぎ、洗濯物の偏り等をはじめとするお客さまの利用方法に起因する場合を除きます。</p>
                        </div>
                   </div>
                </div>
               
                
            </section>
        </>
    );
})

export default PcContents;