import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "components/sp/footer/topPage/index.module.scss"
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby';

const SpFooter: React.FC = () => (
  <footer className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.arrowImgWrap}>
            <ScrollLink to='header' smooth>
                <img
                    src="https://balukoapplp.blob.core.windows.net/images/icon/footer_arrow_icon.png"
                    alt=""
                />
            </ScrollLink>
        </div>
        
        <ul className={styles.underTextList}>
            <li className={styles}>        
                <a href="https://okulab.co.jp/" className={styles.siteLink}><span className='fwb'>運営会社</span></a>
            </li>
            <li className={styles.borderLine}></li>
            <li>
                <a href="https://baluko.jp/policy/" className={styles.siteLink}><span className='fwb'>プライバシーポリシー</span></a>
            </li>
            <li className={styles.borderLine}></li>
            <li className={styles.anchorText}>
                <a href="https://baluko.jp/contact/" className={styles.siteLink}><span className='fwb'>お問い合わせ</span></a>
            </li>
            <li className={styles.borderLine}></li>
            <li>
                <Link to={`/law`} className={styles.siteLink}><span className='fwb'>特定商取引法に基づく表記</span></Link>
            </li>
        </ul> 
        <div className={styles.logoImgWrap}>
            <img
                src="https://balukoapplp.blob.core.windows.net/images/icon/blp_icon.png"
                alt=""
            />
        </div>
      
        <p className={styles.allRight}>© 2022 OKULAB, Inc. All Rights Reserved.</p>
    </div>
  </footer>
)

export default SpFooter;