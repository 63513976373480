import React from "react"
import PropTypes from "prop-types"
import PcHeader from "components/pc/header/common/index"
import SpHeader from "components/sp/header/common/index"
import PcFooter from "components/pc/footer/common/index"
import SpFooter from "components/sp/footer/common/index"
import Seo from "seo"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children}: Props) => {
  const breakPoints = useBreakpoint()
  return (
    <>
      <Seo />
      {breakPoints.sp &&
         <SpHeader />
      }
      {breakPoints.pc &&
        <PcHeader />
      }
      <main>{children}</main>
      {breakPoints.pc &&
        <PcFooter />
      }
      {breakPoints.sp &&
        <SpFooter />
      }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
