import React from "react"
import * as styles from "components/sp/header/topPage/index.module.scss"
import { Link as ScrollLink } from 'react-scroll'

const SpHeader: React.FC = () => {
    return (
        <header className={`${styles.header} jp`} id="header">
            <div className={styles.inner}>
                <div className={styles.blpIconWrap}>
                    <img
                        src="https://balukoapplp.blob.core.windows.net/images/icon/blp_icon.png"
                        alt=""
                    />
                </div>
            </div>
        </header>
    )
}


export default SpHeader;
