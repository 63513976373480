import React from "react"
import * as styles from "components/pc/header/common/index.module.scss"
import { Link } from 'gatsby';

const PcHeader: React.FC = () => {
    return (
        <header className={`${styles.header} jp`} id='header'>
            <div className={styles.inner}>
                <div className={styles.iconWrap}>
                <Link to={`/`}>
                    <img
                        src="https://balukoapplp.blob.core.windows.net/images/icon/blp_icon.png"
                        alt=""
                    />
                </Link>
                    
                </div>
            </div>
        </header>
    )
}


export default PcHeader;
