import React from "react"
import Layout from "layout"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PcContents from "components/pc/lawPage/index"
import SpContents from "components/sp/lawPage/index"

// markup
const IndexPage: React.FC = () => {
    const breakPoints = useBreakpoint()
      return (
        <Layout>
        {breakPoints.pc &&
          <PcContents />
        }
        {breakPoints.sp &&
          <SpContents />
        }
      </Layout>
      )
  }
  
  export default IndexPage